<script setup lang="ts">
import { mdiEmailOutline, mdiPhoneOutline, mdiWhatsapp } from "@mdi/js";

const { t } = useI18n();
</script>

<template>
  <div class="job-offer-card">
    <div>
      <div class="hw-mb-smaller">
        <h2 class="text-h2 text-center mt-12 mb-2 pt-0">
          {{ t("jobOffer.title") }}
        </h2>
        <div class="px-0 px-sm-12 my-4 my-sm-10 my-lg-22">
          <p>
            {{ t("jobOffer.text1") }}
          </p>
          <p>
            {{ t("jobOffer.text2") }}
          </p>
          <p>
            {{ t("jobOffer.text3") }}
          </p>
        </div>
      </div>

      <CollapsableCard
        icon-path="/images/employees/Placeholder.jpg"
        hexagon-color="transparent"
        :title="t('jobOffer.softwareEngineer.title')"
        :collapseLabel="t('general.label.collapse')"
        :expandLabel="t('general.label.expand')"
        object-fit="cover"
      >
        <template #content>
          <p>{{ t("jobOffer.softwareEngineer.text") }}</p>
          <div class="text-right">
            <hw-button
              href="https://jobs.hw.ag/"
              underline
              background="primary"
              class="contact-button mt-4 mt-md-6 mt-xl-8"
              :icon="[mdiEmailOutline]"
              full-padding
              target="_blank"
            >
              {{ t("jobOffer.applyNow") }}
            </hw-button>
          </div>
        </template>
      </CollapsableCard>
    </div>
  </div>
</template>

<style scoped lang="scss">
@use "assets/scss/settings";

//.placerholder-image {
//  min-height: 80px;
//  @media #{map-get(settings.$display-breakpoints, 'sm-and-up')} {
//    max-height: 250px;
//    min-height: 100%;
//  }
//}

.contact-button {
  @media #{map-get(settings.$display-breakpoints, 'xs')} {
    width: 100%;
  }
}

.contact-button.hw-btn-base :deep(.v-btn__content) {
  --hw-btn-color--hover: 255, 255, 255;
}
</style>
